.card-link{
    text-decoration: none;
    color: inherit;
  }
.content-details{
    display: flex;
    justify-content: center;
}
.flex{
    display: flex;
    align-items: center;
    gap: 1em;
}
@media (max-width: 620px) {
    .img-content {
     text-align: center;
    }
  }
.img-content{
    display: flex;
    justify-content: center;
}
.img-content img{
    border: 3px solid red;
}
@media (min-width: 620px) {
    .cardDetails{
        width: 75%;
    }
  }
.cardDetails{
    padding: 0.4em;
    margin-top: 1em;
}
    
.card-image-details {
    max-height: 300px;
}
    
.card-content-details {
}
    
.card-title-details {
}
    
.card-author-details,
.card-actors-details {
    margin: 0;
    padding: 0.1em;
}
    
.card-description-details {
    font-size: 14px;
    color: #555;
}

.box img{
    width: 400px;
}
.box video{
    width: 100%;
}

.btn{
    padding: 0.5em;
}
    