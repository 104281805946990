.carousel-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em;
  z-index: 0;
}
.carousel {
  height: 200px;
  width: 60%;
}
.img{
  height: 200px;
}

@media (max-width: 1200px) {
  .carousel-container{
    display: none;
    width: 100%;
  }
}
