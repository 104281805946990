.card-link{
  text-decoration: none;
  color: inherit;
}
.article{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .card {
    height: 720px;
    max-width: 320px;
    margin: 0.6em;
    padding: 0.4em;
    border: 3px solid red;
  }
  .card-image {
    width: 100%;
  }
  
  .card-content {
    min-height: 8em;
    padding: 0.2em;
  }
  
  .card-title {
    font-weight: bold;
    margin: 0; 
    padding: 0.1em;
  }
  
  .card-author,
  .card-actors {
    margin: 0;
    padding: 0.1em;
  }
  
  .card-description {
    font-size: 14px;
    color: #555;
  }

/* Media query pour les appareils mobiles */
@media (max-width: 767px) {
  .card-link {
    display: flex;
    flex-wrap: wrap;
   justify-content: center;
   margin-bottom: 5em;
  }
  .card {
    max-width: 80%;
    height: 100%;
  }
  .card-title {
    font-size: 1.2em;
  }
  
  .card-author,
  .card-actors {
    font-size: 1em;
  }
  
  .card-description {
   font-size: 1em;
  }
}
  