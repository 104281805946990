.searchData{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

.search-container {
    display: flex;
    align-items: center;
  }
  
  .search-input {
    padding: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    margin-right: 8px;
  }
  
  .search-input:hover {
    border: 2px solid red;
  }

  /* Media query pour les appareils mobiles */
@media (max-width: 767px) {
    .search-container{
      display: none;
    }
}
  