.navbar{
    display: flex;
    background-color: black;
    position: sticky;
    top: 0;
    justify-content: center;
    z-index: 1;
}

.navbar a{
    padding: 14px 20px;
    text-decoration: none;
    color: white;
}
.trailers{
    margin-right: 1em;
}